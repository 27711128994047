import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/landing/landing.module').then((m) => m.LandingPageModule),
  },
  {
    path: 'accounting/:SummaryDate',
    loadChildren: () => import('./pages/accounting/accounting-detail/accounting-detail.module').then((m) => m.AccountingDetailPageModule),
  },
  {
    path: 'accounting',
    loadChildren: () => import('./pages/accounting/accounting-list/accounting-list.module').then((m) => m.AccountingListPageModule),
  },
  {
    path: 'achievements/:AchievementId',
    loadChildren: () => import('./pages/achievement/achievement-detail/achievement-detail.module').then((m) => m.AchievementDetailPageModule),
  },
  {
    path: 'achievements',
    loadChildren: () => import('./pages/achievement/achievement-list/achievement-list.module').then((m) => m.AchievementListPageModule),
  },
  {
    path: 'billing',
    loadChildren: () => import('./pages/billing/billing.module').then((m) => m.BillingPageModule),
  },
  {
    path: 'categories/:ParentProductCategoryId/:ProductCategoryId',
    loadChildren: () => import('./pages/product/category-detail/category-detail.module').then((m) => m.CategoryDetailPageModule),
  },
  {
    path: 'company-settings',
    loadChildren: () => import('./pages/company-settings/company-settings.module').then((m) => m.CompanySettingsPageModule),
  },
  {
    path: 'cooperations/:CooperationId',
    loadChildren: () => import('./pages/cooperation/cooperation-detail/cooperation-detail.module').then((m) => m.CooperationDetailPageModule),
  },
  {
    path: 'cooperations',
    loadChildren: () => import('./pages/cooperation/cooperation-list/cooperation-list.module').then((m) => m.CooperationListPageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'discounts/:DiscountId',
    loadChildren: () => import('./pages/discount/discount-detail/discount-detail.module').then((m) => m.DiscountDetailPageModule),
  },
  {
    path: 'discounts',
    loadChildren: () => import('./pages/discount/discount-list/discount-list.module').then((m) => m.DiscountListPageModule),
  },
  {
    path: 'events/:EventId',
    loadChildren: () => import('./pages/event/event-detail/event-detail.module').then((m) => m.EventDetailPageModule),
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/event/event-list/event-list.module').then((m) => m.EventListPageModule),
  },
  {
    path: 'marketing/:Marketing:Id',
    loadChildren: () => import('./pages/marketing/marketing-detail/marketing-detail.module').then((m) => m.MarketingDetailPageModule),
  },
  {
    path: 'marketing',
    loadChildren: () => import('./pages/marketing/marketing-list/marketing-list.module').then((m) => m.MarketingListPageModule),
  },
  {
    path: 'news/:NewsId',
    loadChildren: () => import('./pages/news/news-detail/news-detail.module').then((m) => m.NewsDetailPageModule),
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news-list/news-list.module').then((m) => m.NewsListPageModule),
  },
  {
    path: 'orders/:OrderId',
    loadChildren: () => import('./pages/order/order-detail/order-detail.module').then((m) => m.OrderDetailPageModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/order/order-list/order-list.module').then((m) => m.OrderListPageModule),
  },
  {
    path: 'printers/:PrinterId',
    loadChildren: () => import('./pages/printer/printer-detail/printer-detail.module').then((m) => m.PrinterDetailPageModule),
  },
  {
    path: 'printers',
    loadChildren: () => import('./pages/printer/printer-list/printer-list.module').then((m) => m.PrinterListPageModule),
  },
  {
    path: 'product-combinations/:ProductCombinationId',
    loadChildren: () => import('./pages/product-combination/product-combination-detail/product-combination-detail.module').then((m) => m.ProductCombinationDetailPageModule),
  },
  {
    path: 'products/:ProductCategoryId/:ProductId',
    loadChildren: () => import('./pages/product/product-detail/product-detail.module').then((m) => m.ProductDetailPageModule),
  },
  {
    path: 'product-settings',
    loadChildren: () => import('./pages/product/product.module').then((m) => m.ProductPageModule),
  },
  {
    path: 'product-combinations',
    loadChildren: () => import('./pages/product-combination/product-combination-list/product-combination-list.module').then((m) => m.ProductCombinationListPageModule),
  },
  {
    path: 'qrcodes/:QrCodeId',
    loadChildren: () => import('./pages/qrcode/qrcode-detail/qrcode-detail.module').then((m) => m.QrcodeDetailPageModule),
  },
  {
    path: 'qrcodes',
    loadChildren: () => import('./pages/qrcode/qrcode-list/qrcode-list.module').then((m) => m.QrcodeListPageModule),
  },
  {
    path: 'sections/:SectionId',
    loadChildren: () => import('./pages/section/section-detail/section-detail.module').then((m) => m.SectionDetailPageModule),
  },
  {
    path: 'sections',
    loadChildren: () => import('./pages/section/section-list/section-list.module').then((m) => m.SectionListPageModule),
  },
  {
    path: 'schedule',
    loadChildren: () => import('./pages/schedule/schedule.module').then((m) => m.SchedulePageModule),
  },
  {
    path: 'selection',
    loadChildren: () => import('./pages/selection/selection.module').then((m) => m.SelectionPageModule),
  },
  {
    path: 'setup',
    loadChildren: () => import('./pages/setup/setup.module').then((m) => m.SetupPageModule),
  },
  {
    path: 'spots/:SpotId',
    loadChildren: () => import('./pages/spot/spot-detail/spot-detail.module').then((m) => m.SpotDetailPageModule),
  },
  {
    path: 'spots',
    loadChildren: () => import('./pages/spot/spot-list/spot-list.module').then((m) => m.SpotListPageModule),
  },
  {
    path: 'stations/:StationId',
    loadChildren: () => import('./pages/station/station-detail/station-detail.module').then((m) => m.StationDetailPageModule),
  },
  {
    path: 'stations',
    loadChildren: () => import('./pages/station/station-list/station-list.module').then((m) => m.StationListPageModule),
  },
  {
    path: 'users/:UserId',
    loadChildren: () => import('./pages/user/user-detail/user-detail.module').then((m) => m.UserDetailPageModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/user/user-list/user-list.module').then((m) => m.UserListPageModule),
  },
  {
    path: 'absence',
    loadChildren: () => import('./pages/absence/absence.module').then((m) => m.AbsencePageModule),
  },
  {
    path: 'reservations',
    loadChildren: () => import('./pages/reservation/reservation-list/reservation-list.module').then((m) => m.ReservationListPageModule),
  },
  {
    path: 'reservations/:ReservationId',
    loadChildren: () => import('./pages/reservation/reservation-detail/reservation-detail.module').then((m) => m.ReservationDetailPageModule),
  },
  {
    path: 'blacklist',
    loadChildren: () => import('./pages/blacklist/blacklist-list/blacklist-list.module').then((m) => m.BlacklistListPageModule),
  },
  {
    path: 'blacklist/:BlackListId',
    loadChildren: () => import('./pages/blacklist/blacklist-detail/blacklist-detail.module').then((m) => m.BlacklistDetailPageModule),
  },
  {
    path: 'statistics',
    loadChildren: () => import('./pages/statistics/statistics.module').then((m) => m.StatisticsPageModule),
  },
  {
    path: 'cash-balances',
    loadChildren: () => import('./pages/cash-balance/cash-balance-list/cash-balance-list.module').then((m) => m.CashBalanceListPageModule),
  },
  {
    path: 'cash-balances/:CashBalanceId',
    loadChildren: () => import('./pages/cash-balance/cash-balance-detail/cash-balance-detail.module').then((m) => m.CashBalanceDetailPageModule),
  },
  {
    path: 'opening-times',
    loadChildren: () => import('./pages/opening-times/opening-times.module').then( m => m.OpeningTimesPageModule)
  },
  {
    path: 'csv',
    loadChildren: () => import('./pages/csv/csv.module').then( m => m.CsvPageModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./pages/offer/offer-list/offer-list.module').then( m => m.OfferListPageModule)
  },
  {
    path: 'offers/:OfferId',
    loadChildren: () => import('./pages/offer/offer-detail/offer-detail.module').then( m => m.OfferDetailPageModule)
  },
  {
    path: 'product-group-selection',
    loadChildren: () => import('./modals/product-group-selection/product-group-selection.module').then( m => m.ProductGroupSelectionPageModule)
  },
  {
    path: 'product-groups',
    loadChildren: () => import('./pages/product-group/product-group-list/product-group-list.module').then( m => m.ProductGroupListPageModule)
  },
  {
    path: 'product-groups/:ProductGroupId',
    loadChildren: () => import('./pages/product-group/product-group-detail/product-group-detail.module').then( m => m.ProductGroupDetailPageModule)
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
