import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {HeadComponent} from './head.component';
import {UserInfoModule} from "../../directives/user-info/user-info.module";

@NgModule({
  declarations: [HeadComponent],
  exports: [HeadComponent],
  imports: [
    CommonModule,
    IonicModule,
    UserInfoModule
  ]
})
export class HeadModule { }
