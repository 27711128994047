import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-view-loader',
  templateUrl: './view-loader.component.html',
  styleUrls: ['./view-loader.component.scss'],
})
export class ViewLoaderComponent implements OnInit {
  // @Input() IsLoading: boolean;

  constructor() {
  //  this.IsLoading = true;
  }
  ngOnInit() {
  //  this.IsLoading = true;
  }
}
