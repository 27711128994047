import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {UserInfoComponent} from './user-info.component';
import {ViewInfoModule} from '../view-info/view-info.module';
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [UserInfoComponent],
  exports: [UserInfoComponent],
  imports: [
    CommonModule,
    IonicModule,
    ViewInfoModule,
    NgbDropdownModule,
  ]
})
export class UserInfoModule { }
