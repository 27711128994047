import { AdminPanelComponent } from './directives/admin-panel/admin-panel.component';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import localeDe from '@angular/common/locales/de';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './app.auth.interceptor';
import { registerLocaleData } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgPipesModule } from 'ngx-pipes';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { QRCodeModule } from 'angularx-qrcode';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { AddWidgetModalComponent } from './directives/add-widget-modal/add-widget-modal.component';
import { NewsWidgetComponent } from './widgets/news-widget/news-widget.component';
import { EventsWidgetComponent } from './widgets/events-widget/events-widget.component';
import { HeadModule } from './layout/head/head.module';
import { UserInfoModule } from './directives/user-info/user-info.module';
import { ViewInfoModule } from './directives/view-info/view-info.module';
import { ExportModule } from './directives/export/export.module';
import { PaginationModule } from './directives/pagination/pagination.module';
import { ViewLoaderModule } from './directives/view-loader/view-loader-module';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { FileUploadModule } from './directives/file-upload/file-upload.module';
@NgModule({
  declarations: [AppComponent, SidebarComponent, AddWidgetModalComponent, NewsWidgetComponent, EventsWidgetComponent, AdminPanelComponent,],
  entryComponents: [NewsWidgetComponent, EventsWidgetComponent],
  imports: [
    BrowserModule,
    EditorModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    DragDropModule,
    FormsModule,
    NgSelectModule,
    QRCodeModule,
    DragDropModule,
    HeadModule,
    UserInfoModule,
    ViewInfoModule,
    ExportModule,
    PaginationModule,
    ViewLoaderModule,
    BrowserAnimationsModule,
    NgPipesModule,
    ChartsModule,
    FileUploadModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    QRScanner,
    ThemeService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: LOCALE_ID, useValue: 'de' },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe, 'de');
  }
}
