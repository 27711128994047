import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ViewInfoModule} from '../view-info/view-info.module';
import {PaginationComponent} from './pagination.component';
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [PaginationComponent],
  exports: [PaginationComponent],
  imports: [
    CommonModule,
    IonicModule,
    ViewInfoModule,
    FormsModule,
  ]
})
export class PaginationModule { }
