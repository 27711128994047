import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadService } from './file-upload.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input() file: string;
  @Input() index: number;
  @Input() placeholder: string;
  @Output() fileChange = new EventEmitter<string>();

  fileToUpload: File = null;
  processingUpload = false;
  FileIsImage = true;

  constructor(private fileUploadService: FileUploadService, public toastController: ToastController, private modalService: NgbModal) {
    this.index = this.index || makeid(10);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.uploadFileToActivity();
  }

  uploadFileToActivity() {
    this.processingUpload = true;
    this.FileIsImage = true;
    this.fileUploadService
      .postFile(this.fileToUpload)
      .then((data) => {
        this.file = data;
        this.fileChange.emit(data);
        this.processingUpload = false;
        this.showSuccess();
      })
      .catch((error) => {
        this.processingUpload = false;
        this.showError();
        console.error('err', error);
      });
  }

  removeFile() {
    this.file = null;
    this.FileIsImage = true;
    this.fileChange.emit('');
  }

  async showSuccess() {
    const toast = await this.toastController.create({
      message: 'Bitte speichern Sie, um die Zuordnung beizubehalten.',
      duration: 2000,
      color: 'success',
    });
    await toast.present();
  }
  async showError() {
    const toast = await this.toastController.create({
      message: 'Die Bilddatei konnte nicht hochgeladen werden!',
      duration: 2000,
      color: 'danger',
    });
    await toast.present();
  }

  checkIfFileIsImage($event) {
    this.FileIsImage = false;
    $event.error = null;
  }
  openModal(Modal) {
    this.modalService.open(Modal, { windowClass: 'modal-animated image-modal', centered: true, keyboard: false });
  }
  closeModal() {
    this.modalService.dismissAll();
  }
}

function makeid(length): number {
  let result = '';
  const characters = '0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return parseInt(result, 10);
}
