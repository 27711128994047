import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ViewInfoModule} from '../view-info/view-info.module';
import {ViewLoaderComponent} from './view-loader.component';

@NgModule({
  declarations: [ViewLoaderComponent],
  exports: [ViewLoaderComponent],
  imports: [
    CommonModule,
    IonicModule,
    ViewInfoModule,
  ]
})
export class ViewLoaderModule { }
