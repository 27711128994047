import { UrlService } from './url.service';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  User: User;
  Companies: Company[];
  Url = this.urlService.AccountUrl;
  AppId = false ? '94853d15-fa5b-4226-b62c-7bc0aedbe88f' : '169e808a-5885-45d0-aa8e-796c2e83fdcf';
  constructor(
    private api: ApiService, //
    private router: Router,
    private urlService: UrlService
  ) {
    this.init();
  }

  async init() {
    await this.getUser();
    await this.getUserCompanies();
  }

  async getUser() {
    try {
      this.User = (await this.api.get('users/me')) as User;
      return this.User;
    } catch (Err) {
        localStorage.setItem('ErrorStatus', JSON.stringify(Err.status));
        localStorage.removeItem('x-api-key');
        await this.router.navigate(['/']);
    }
  }

  public hasRole(RoleName: string): boolean {
    if (this.User && this.User.Roles && this.User.Roles.length > 0) {
      if (this.User.Roles.indexOf('Administrator') >= 0 || this.User.Roles.indexOf('Inhaber') >= 0) {
        return true;
      } else if (this.User.Roles.indexOf(RoleName) >= 0) {
        return true;
      } else {
        if (RoleName === 'Angestellter' && this.User.Roles.indexOf('Leiter') >= 0) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  async getUserCompanies() {
    try {
      this.Companies = (await this.api.get('users/companies')) as Company[];
      if (this.Companies.length === 0) {
        await this.router.navigate(['/setup']);
      } else if (!localStorage.getItem('company-id') && location && location.pathname !== '/selection') {
        await this.router.navigate(['/selection']);
      } else {
        return this.Companies;
      }
    } catch (Err) {
   
      await this.router.navigate(['/']);
    }
  }

  getAppId() {
    return this.AppId;
  }
}
