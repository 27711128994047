import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
})
export class AdminPanelComponent implements OnInit {
  constructor(private modalService: NgbModal, private toastr: ToastrService) {}
  DevelopmentMode = false;
  ngOnInit() {
    if (location.href.indexOf('4200') !== -1) {
      this.DevelopmentMode = true;
    }
  }

  async openAdminModal(Modal, Section) {
    this.modalService.open(Modal, { windowClass: 'modal-animated', scrollable: true, centered: true });
  }

  async closeModal(Modal) {
    Modal.close();
  }
  setApiUrl(Url) {
    localStorage.setItem('api-url', Url);
  }
  setAccountUrl(Url) {
    localStorage.setItem('account-url', Url);
  }
  checkActiveApiUrl(Url) {
    if (localStorage.getItem('api-url') === Url) {
      return true;
    }
  }
  checkActiveAccountUrl(Url) {
    if (localStorage.getItem('account-url') === Url) {
      return true;
    }
  }
  removeItem(Item) {
    localStorage.removeItem(Item);
  }
  reloadApp() {
    window.location.reload();
  }
}
