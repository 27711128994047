import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss'],
})
export class NewsWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
