import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ViewInfoComponent} from './view-info.component';

@NgModule({
  declarations: [ViewInfoComponent],
  exports: [ViewInfoComponent],
  imports: [
    CommonModule,
    IonicModule,
  ]
})
export class ViewInfoModule { }
