import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  ApiUrl = 'https://api.favio.app/';
  AccountUrl = 'https://account.youco-it.com/';



  constructor() {}
}
