import { Component, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-view-info',
  templateUrl: './view-info.component.html',
  styleUrls: ['./view-info.component.scss'],
})
export class ViewInfoComponent {

  constructor(private modalService: NgbModal) {}
  @Input() ViewInfo: string;

  open(content) {
    this.modalService.open(content);
  }
}
