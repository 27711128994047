import {Component, Input, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-head',
    templateUrl: './head.component.html',
    styleUrls: ['./head.component.scss'],

})
export class HeadComponent implements OnInit {
    @Input() PageTitle: string;
    @Input() ViewInfo: string;

    constructor(private TitleService: Title,
    ) {
        this.PageTitle = typeof this.PageTitle === 'undefined' ? 'Titel fehlt!' : this.PageTitle;
        this.TitleService.setTitle(this.PageTitle);
    }

    ngOnInit() {
        this.TitleService.setTitle(this.PageTitle);
    }

}
