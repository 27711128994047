import { Component, OnInit, Input } from '@angular/core';
import domtoimage from 'dom-to-image';
import {jsPDF} from 'jspdf';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
export class ExportComponent {
  @Input() exportAs: { pdf: boolean; xlsx: boolean; };
  @Input() elementId;
  @Input() fileName;
  @Input() exportData;

  constructor() {
    this.exportAs =
    typeof this.exportAs === 'undefined'
      ? {
          pdf: true,
          xlsx: true,
        }
      : this.exportAs;
  }

  exportToPDF() {
    const div = document.getElementById(this.elementId);
    const divHeight = div.clientHeight;
    const divWidth = div.clientWidth;
    const options = { background: 'white', width: divWidth, height: divHeight };

    domtoimage.toPng(div, options).then((imgData) => {
      const doc = new jsPDF('p', 'mm', [divWidth, divHeight]);
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      doc.save(this.fileName + '.pdf');
    });
  }

  exportToXlsx() {
    try {
      let Data = [];
      if(Array.isArray(this.exportData)) {
        Data = this.exportData;
      } else {
        for(let key of Object.keys(this.exportData)) {
          this.exportData[key].Key = key;
          Data.push(this.exportData[key])
        }
      }
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(Data);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, this.fileName + '.xlsx');
    } catch (err) {

    }
  }

}
