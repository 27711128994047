import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-widget-modal',
  templateUrl: './add-widget-modal.component.html',
  styleUrls: ['./add-widget-modal.component.scss'],
})
export class AddWidgetModalComponent implements OnInit {
  @Output() Action: EventEmitter<string> = new EventEmitter(true);
  AvailableWidgets = ['Nachrichten', 'Events'];

  constructor(
      private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
  }

  async openModal(Modal) {
    this.modalService.open(Modal, {windowClass: 'modal-animated', scrollable: true, centered: true});

  }

  async addWidget(modal, WidgetName) {
    await this.Action.emit(WidgetName);
    modal.close();
  }
  async closeModal(modal) {
    modal.close();
  }
}
