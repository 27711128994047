import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() PaginationInfo: {
    Total: number;
    AmountPerPage: number;
    CurrentMin: number;
    CurrentMax: number;
    CurrentPage: number;
  };
  CalculatedPages = [];

  constructor() {

  }

  async ngOnInit() {

    await this.calculatePages();
  }

  async calculatePages() {
    this.CalculatedPages = Array(Math.ceil(this.PaginationInfo.Total / this.PaginationInfo.AmountPerPage)).fill(0).map((x, i) => i);
  }

  setActivePage(Num, GoOnePage) {
    if (GoOnePage) {
      this.PaginationInfo.CurrentPage = this.PaginationInfo.CurrentPage + Num;
    } else {
      this.PaginationInfo.CurrentPage = Num;
    }
  }
}
