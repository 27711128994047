import { Router } from '@angular/router';
import { UrlService } from './url.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private api = this.urlService.ApiUrl;

  constructor(
    private urlService: UrlService, //
    private http: HttpClient,
    public toastr: ToastrService,
    private router: Router
  ) {}

  async get(Path: string, Params: {} = {}, disableWarning = false): Promise<any> {
    return this.Get(Path, Params, disableWarning);
  }

  async Get(Path: string, Params: {} = {}, disableWarning = false): Promise<any> {
    try {
      const item = await this.http.get(this.api + Path, { params: Params }).toPromise();
      return item;
    } catch (Err) {
      if (location.href.indexOf('4200') !== -1) {
        // console.error(Err);
      }
      if(Err.status === 409) {
         localStorage.removeItem('x-api-key');
         await this.router.navigate(['/']);
      } else if(Err.status === 0){
        this.toastr.error('Der Server konnte nicht erreicht werden.');
      }
      throw Err;
    }
  }

  async save(Path: string, Object: any, SuccessMessage: string = '', Params: {} = {}): Promise<any> {
    return this.Save(Path, Object, SuccessMessage, Params);
  }

  async post(Path: string, Object: any, SuccessMessage: string = '', Params: {} = {}): Promise<any> {
    return this.Save(Path, Object, SuccessMessage, Params);
  }

  async Save(Path: string, Object: any, SuccessMessage: string = '', Params: {} = {}): Promise<any> {
    return this.SavePlain(Path, Object, SuccessMessage, Params, {
      Method: 'Save',
      Path,
      Params,
      Object,
      SendCount: 1,
      CreatedAt: new Date(),
    });
  }

  async SavePlain(Path: string, Object: any, SuccessMessage: string = '', Params: {} = {}, QueueItem: QueueItem): Promise<any> {
    try {
      const responseObject = await this.http.post(this.api + Path, Object, { params: Params }).toPromise();
      if (SuccessMessage.length > 3) {
        this.toastr.success(SuccessMessage, 'Erfolg');
      }
      return responseObject;
    } catch (Err) {
      if (location.href.indexOf('4200') !== -1) {
        console.error(Err);
      }
      if (SuccessMessage.length > 3) {
        this.toastr.error('Es wurden nicht genügend Angaben gemacht.', 'Fehler');
      }
      return QueueItem.Object;
    }
  }

  async delete(Path: string, SuccessMessage: string = '') {
    return this.Delete(Path, SuccessMessage);
  }

  async Delete(Path: string, SuccessMessage: string = '') {
    return this.DeletePlain(Path, SuccessMessage, {
      Method: 'Delete',
      Path,
      Params: {},
      SendCount: 1,
      CreatedAt: new Date(),
      SuccessMessage,
    });
  }

  async DeletePlain(Path: string, SuccessMessage: string = '', QueueItem: QueueItem): Promise<any> {
    try {
      const responseObject = await this.http.delete(this.api + Path).toPromise();
      if (SuccessMessage.length > 3) {
        this.toastr.success(SuccessMessage, 'Erfolg');
      }
      return responseObject;
    } catch (Err) {
      if (location.href.indexOf('4200') !== -1) {
      }
      if (SuccessMessage.length > 3) {
        this.toastr.error('Es konnte keine Verbindung zum Server hergestellt werden. Der Löschvorgang wird in die Warteschlange aufgenommen.', 'Fehler'
        );
      }
      return null;
    }
  }
}
