import { Component, Inject, Input, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { UrlService } from '../../services/url.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  @Input() PageTitle: string;
  @Input() ViewInfo: string;

  isFullscreen = false;
  elem;
  AccountUrl = this.urlService.AccountUrl;
  constructor(
    private authService: AuthService,
    private TitleService: Title,
    private api: ApiService,
    private router: Router,
    config: NgbDropdownConfig,
    private urlService: UrlService,
    private auth: AuthService,
    @Inject(DOCUMENT) private document: any
  ) {
    config.placement = 'bottom-right';
    config.container = 'body';
    this.PageTitle = typeof this.PageTitle === 'undefined' ? 'Titel fehlt!' : this.PageTitle;
    this.TitleService.setTitle(this.PageTitle);
  }

  async ngOnInit() {
    this.elem = document.documentElement;
  }
  async logout() {
    localStorage.removeItem('x-api-key');
    localStorage.removeItem('company-id');
    await this.router.navigate(['/']);
    //TODO NOPE! location.href = 'https://beta.account.teamcraft.app/logout';
  }
  toggleFullScreen() {
    if (this.isFullscreen) {
      this.closeFullscreen();
    } else {
      this.openFullscreen();
    }
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
      this.isFullscreen = true;
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
      this.isFullscreen = true;
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
      this.isFullscreen = true;
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
      this.isFullscreen = true;
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
      this.isFullscreen = false;
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
      this.isFullscreen = false;
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
      this.isFullscreen = false;
    }
  }
  goToSetup() {
    localStorage.removeItem('company-id');
    this.router.navigate(['setup']);
  }
  goToSelection() {
    this.router.navigate(['selection']);
  }
  async login() {
    window.open(this.auth.Url + 'branding/favio', '_blank');
  }
}
